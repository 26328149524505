import React from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import LandingPage from "components/LandingPage/LandingPage"
import ResetPasswordPage from "components/ResetPasswordPage/ResetPasswordPage"
import VerifyEmailPage from "components/VerifyEmailPage/VerifyEmailPage"

interface AppPathRouterProps {}

const AppPathRouter: React.FC<AppPathRouterProps> = () => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/reset-password`}>
        <ResetPasswordPage />
      </Route>
      <Route path={`${path}/verify-email`}>
        <VerifyEmailPage />
      </Route>
      <Route path={`${path}`}>
        <LandingPage />
      </Route>
    </Switch>
  )
}

export default AppPathRouter
