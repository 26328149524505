import React from "react"
import { logoUrl } from "config"
import styles from "./Logo.module.scss"

interface LogoProps {}

const Logo: React.FC<LogoProps> = () => {
  return <img className={styles.logo} src={logoUrl} alt="Logo" />
}

export default Logo
