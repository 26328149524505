import { ThemeName } from "config"
import React, { useRef, useLayoutEffect, useMemo, ReactNode } from "react"
import { defaultThemeConfig, partnerThemeConfigs, ThemeConfig } from "./themeConfig"

export interface ThemeProps {
  name?: ThemeName
  children: ReactNode
}

export const ThemeContext = React.createContext(defaultThemeConfig)

const Theme = ({ name = "default", children }: ThemeProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, partner] = name.match(/^(\w+)?-?([\w-]+)?$/)! as [unknown, ThemeName]

  const theme: ThemeConfig = useMemo(
    () => ({
      ...defaultThemeConfig,
      ...partnerThemeConfigs[partner]
    }),
    [name, partner]
  )

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty("--primaryColor", theme.primaryColor)
      ref.current.style.setProperty("--layoutBackgroundColor", theme.layoutBackgroundColor)
      ref.current.style.setProperty("--transactionalMainText", theme.transactionalMainText)
      ref.current.style.setProperty("--formMainButtonVariant", theme.formMainButtonVariant)
      ref.current.style.setProperty("--inputBackgroundColor", theme.inputBackgroundColor)
      ref.current.style.setProperty("--inputBorderColor", theme.inputBorderColor)
      ref.current.style.setProperty("--inputTextColor", theme.inputTextColor)
      ref.current.style.setProperty("--inputErrorBackgroundColor", theme.inputErrorBackgroundColor)
      ref.current.style.setProperty("--inputErrorTextColor", theme.inputErrorTextColor)
      ref.current.style.setProperty("--inputPlaceholderColor", theme.inputPlaceholderColor)
      ref.current.style.setProperty("--greyLightColor", theme.greyLightColor)
    }
  }, [name, theme])

  return (
    <ThemeContext.Provider value={theme}>
      <div ref={ref}>{children}</div>
    </ThemeContext.Provider>
  )
}

export default Theme
