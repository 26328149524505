import React from "react"
import styles from "./Button.module.scss"
import classnames from "classnames"

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  variant: "primaryInverted" | "dark"
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, variant, className, ...props }, ref) => {
  const classes = classnames(styles.button, className, styles[variant], {
    [styles.disabled]: props.disabled
  })
  return (
    <button ref={ref} className={classes} {...props}>
      {children}
    </button>
  )
})

export default Button
