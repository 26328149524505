import { useState, useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import styles from "./ResetPasswordForm.module.scss"
import { postPasswordReset } from "api/requests"
import { ApiError } from "api/api"
import { useLocation } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { ThemeContext } from "components/Theme/Theme"
import { passwordLength } from "config"
import Button from "components/Button/Button"
import ControlledInput from "components/ControlledInput/ControlledInput"

const ResetPasswordForm = () => {
  const [serverError, setServerError] = useState("")
  const [success, setSuccess] = useState(false)
  const [code, setCode] = useState<string | null>(null)
  const intl = useIntl()
  const location = useLocation()
  const theme = useContext(ThemeContext)

  useEffect(() => {
    setCode(new URLSearchParams(location.search).get("code"))
  }, [location])

  return (
    <>
      {serverError ? (
        <>
          <h1 className={styles.title}>
            <FormattedMessage id="resetpassword.title" />
          </h1>
          <p className={styles.description}>
            <FormattedMessage id="verifyemail.page.fail.text" />
          </p>
        </>
      ) : (
        <>
          <h1 className={styles.title}>
            <FormattedMessage id="resetpassword.title" />
          </h1>
          <p className={styles.description}>
            {intl.formatMessage({ id: "resetpassword.description" }, { count: process.env.REACT_APP_PASSWORD_LENGTH })}
          </p>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={Yup.object({
              password: Yup.string()
                .min(
                  passwordLength,
                  intl.formatMessage({ id: "generic.input.validation.minlength" }, { count: passwordLength })
                )
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~])/,
                  intl.formatMessage({ id: "resetpassword.input.validation.passwordformat" })
                ),
              confirmPassword: Yup.string()
                .equals([Yup.ref("password")], intl.formatMessage({ id: "generic.input.validation.password.match" }))
                .required(intl.formatMessage({ id: "generic.input.validation.required" }))
            })}
            onSubmit={async values => {
              setServerError("")
              try {
                await postPasswordReset({ password: values.password, code: code || "" })
                setSuccess(true)
              } catch (error) {
                if (error instanceof ApiError && error.localizedMessage) {
                  setServerError(error.localizedMessage)
                } else {
                  setServerError(intl.formatMessage({ id: "generic.error" }))
                }
              }
            }}>
            {formik => (
              <Form className={styles.form}>
                <ControlledInput
                  name="password"
                  className={styles.input}
                  type="password"
                  placeholder={intl.formatMessage({ id: "resetpassword.input.newpassword" })}
                />
                <ControlledInput
                  name="confirmPassword"
                  className={styles.input}
                  type="password"
                  placeholder={intl.formatMessage({ id: "resetpassword.input.confirmpassword" })}
                />
                {serverError ? <div className={styles.error}>{serverError}</div> : null}
                <Button
                  variant={theme.formMainButtonVariant}
                  className={styles.button}
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid || success}>
                  {intl.formatMessage({ id: "resetpassword.button" })}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}

      {success && (
        <p className={styles.success}>
          <span>
            <FormattedMessage id="resetpassword.success.row1" />
          </span>
          <span>
            <FormattedMessage id="resetpassword.success.row2" />
          </span>
        </p>
      )}
    </>
  )
}

export default ResetPasswordForm
