import { ThemeName } from "config"

import { ButtonProps } from "components/Button/Button"

export type ThemeConfig = {
  primaryColor: string
  layoutBackgroundColor: string
  transactionalMainText: string
  formMainButtonVariant: ButtonProps["variant"]
  inputBackgroundColor: string
  inputErrorBackgroundColor: string
  inputErrorTextColor: string
  inputBorderColor: string
  inputTextColor: string
  inputPlaceholderColor: string
  greyLightColor: string
}

// Doktor theme is the default one
export const defaultThemeConfig: ThemeConfig = {
  primaryColor: "#009487",
  layoutBackgroundColor: "#009487",
  transactionalMainText: "#FFFFFF",
  formMainButtonVariant: "primaryInverted",
  inputBackgroundColor: "#27a499",
  inputTextColor: "#ffffff",
  inputErrorBackgroundColor: "#ffffff",
  inputErrorTextColor: "#0b253b",
  inputBorderColor: "#27a499",
  inputPlaceholderColor: "#ffffff",
  greyLightColor: "#e1e1e1"
}

// Partner overrides of default values
export const partnerThemeConfigs: { [key in ThemeName]?: Partial<ThemeConfig> } = {
  default: defaultThemeConfig,

  anicura: {
    formMainButtonVariant: "dark",
    layoutBackgroundColor: "#92d1dd",
    inputTextColor: "#0b253b",
    inputPlaceholderColor: "#84c2cf",
    inputBackgroundColor: "#b0dee7",
    inputBorderColor: "#89C9D4",
    inputErrorBackgroundColor: "#b0dee7"
  },

  doktor: {
    primaryColor: "#005E49",
    layoutBackgroundColor: "#005E49",
    greyLightColor: "#D9D3CE"
  },

  proximus: {
    transactionalMainText: "#00855e",
    layoutBackgroundColor: "#D4F5EB",
    inputTextColor: "#00855e",
    inputErrorTextColor: "#00855e",
    inputPlaceholderColor: "#00855e",
    inputBackgroundColor: "#E5F8F3",
    inputErrorBackgroundColor: "#E5F8F3",
    inputBorderColor: "#E5F8F3"
  }
}
