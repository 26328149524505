import React from "react"
import styles from "./Input.module.scss"
import classnames from "classnames"

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  type: "password"
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, children, error, ...props }, ref) => {
  const inputClasses = classnames(styles.input, {
    [styles.error]: !!error,
    [styles.hasValue]: !!props.value || !!error
  })
  const inputProps = { ...props, placeholder: error ? "" : props.placeholder }
  return (
    <div className={classnames(styles.wrapper, className)}>
      <input className={inputClasses} ref={ref} {...inputProps} />
      {(props.value || error) && (
        <div className={classnames(styles.placeholderText, { [styles.placeholderTextError]: !!error })}>
          {props.placeholder}
        </div>
      )}
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  )
})

export default Input
