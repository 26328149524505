export type Environment = "development" | "testing" | "staging" | "production"

export type Platform = "doktor" | "anicura" | "proximus" | "doktorde"

export type ThemeName = "doktor" | "anicura" | "proximus" | "default"

export type FeatureFlags = ""
const featureFlagsEnv = process.env.REACT_APP_FEATURE_FLAGS
export const featureFlags = new Set((featureFlagsEnv?.split(",").map(f => f.trim()) || []) as FeatureFlags[])

export const patientApi = (process.env.REACT_APP_API || "") + (process.env.REACT_APP_SERVICE_ZONE || "")

export const themeName: ThemeName = (process.env.REACT_APP_THEME as ThemeName) || "default"

export const useFallbackLanguage: boolean = process.env.REACT_APP_TRANSLATION_USE_FALLBACK_LANG === "true"

export const translationFileSuffix: string = process.env.REACT_APP_TRANSLATION_FILE_SUFFIX || ""

export const baseLanguage: string = process.env.REACT_APP_TRANSLATION_BASE_LANGUAGE || "en"

const isPlatform = (platform?: string): platform is Platform => {
  const knownPlatforms: Platform[] = ["doktor", "anicura", "proximus", "doktorde"]
  return !!platform && knownPlatforms.includes(platform as Platform)
}
const platformEnv = process.env.REACT_APP_PLATFORM
if (!isPlatform(platformEnv)) {
  throw new Error(`Unknown platform config ${platformEnv}`)
}
export const platform: Platform = platformEnv

const isEnvironment = (environment?: string): environment is Environment => {
  const knownEnvironments: Environment[] = ["development", "staging", "testing", "preview" as any, "production"]
  return !!environment && knownEnvironments.includes(environment as Environment)
}
const environmentEnv = process.env.REACT_APP_ENVIRONMENT
if (!isEnvironment(environmentEnv)) {
  throw new Error(`Unknown environment config ${environmentEnv}`)
}
export const environment: Environment = environmentEnv

export const datadogConfig = {
  applicationId: process.env.REACT_APP_DATADOG_APP_ID!,
  clientToken: process.env.REACT_APP_DATADOG_TOKEN!,
  environment,
  partner: platform,
  version: process.env.REACT_APP_VERSION!,
  service: "transactional-ui"
}

export const knownLanguages: string[] = process.env.REACT_APP_TRANSLATION_KNOWN_LANGUAGES?.trim()
  .split(",")
  .map(l => l.trim()) || ["en"]

export const getTranslationFileLanguageCode = (countryCode: string | undefined, languageCode: string | undefined) => {
  if (countryCode === "de" && languageCode === "de") {
    return "de_DE"
  }
  return languageCode
}

let logoUrl: string
async function getLogo() {
  const logoModule = await import(`images/${process.env.REACT_APP_ASSETS}/logo.svg`)
  if (logoModule && logoModule.default) {
    logoUrl = logoModule.default
  }
}
getLogo()
export { logoUrl }

export const googlePlayUrl = process.env.REACT_APP_ANDROID_LINK
export const appleStoreUrl = process.env.REACT_APP_IOS_LINK

export const passwordLength = Number.parseInt(process.env.REACT_APP_PASSWORD_LENGTH || "8")
