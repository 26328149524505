import React from "react"
import Input, { InputProps } from "../Input/Input"
import { useField } from "formik"

export interface ControlledInputProps extends InputProps {
  name: string
}

const ControlledInput = React.forwardRef<HTMLInputElement, ControlledInputProps>(({ ...props }, ref) => {
  const [field, meta] = useField(props)
  return <Input ref={ref} {...field} {...props} error={meta.touched && meta.error ? meta.error : undefined} />
})

export default ControlledInput
