import ResetPasswordForm from "components/ResetPasswordForm/ResetPasswordForm"
import ResetPasswordPageHead from "./ResetPasswordPageHead"
import Layout from "components/Layout/Layout"
import Logo from "components/Logo/Logo"

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPasswordPageHead />
      <Layout>
        <Logo />
        <ResetPasswordForm />
      </Layout>
    </>
  )
}

export default ResetPasswordPage
