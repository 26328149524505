import { patientApiFetch } from "./api"

interface PostPasswordResetRequest {
  password: string
  code: string
}

interface PostPasswordResetResponse {
  token: string
}

interface PostEmailVerificationRequest {
  token: string
}

interface PostEmailVerificationResponse {
  result: string
}

export const postPasswordReset = (body: PostPasswordResetRequest) => {
  return patientApiFetch<PostPasswordResetResponse>("/login/email_password/reset/done", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" }
  })
}

export const postEmailVerification = (body: PostEmailVerificationRequest) => {
  return patientApiFetch<PostEmailVerificationResponse>("/account/email/verify/done", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" }
  })
}
