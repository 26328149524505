import classnames from "classnames"

import styles from "./Loader.module.scss"

const Loader = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.loader}>
      <div className={classnames(styles.dot, styles.one)} />
      <div className={classnames(styles.dot, styles.two)} />
      <div className={classnames(styles.dot, styles.three)} />
      <div className={classnames(styles.dot, styles.four)} />
    </div>
  </div>
)

export default Loader
