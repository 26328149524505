import React from "react"
import { createRoot } from 'react-dom/client';

import App from "./components/App/App"
import { datadogConfig } from "./config"
import * as serviceWorker from "./serviceWorker"
import { datadogInit, ErrorBoundary } from "@doktor-se/more-rum-please"
import "./index.scss"

datadogInit(datadogConfig)

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
