import React from "react"

import { themeName } from "config"
import AppRouter from "components/AppRouter/AppRouter"
import Theme from "components/Theme/Theme"

const App: React.FC = () => {
  return (
    <Theme name={themeName}>
      <AppRouter />
    </Theme>
  )
}

export default App
