import React from "react"
import { Helmet } from "react-helmet-async"
import { useIntl } from "react-intl"

interface LandingPageHeadProps {}

const LandingPageHead: React.FC<LandingPageHeadProps> = () => {
  const intl = useIntl()
  return (
    <Helmet>
      <title>{intl.formatMessage({ id: "landingpage.page.title" })}</title>
    </Helmet>
  )
}

export default LandingPageHead
